<template>
  <v-card style="border-radius: 8px" class="shadow trainer_card" @click="viewPromotion()">
    <v-card-text >
      <v-row dense class="border-bottom pb-2">
        <v-col cols="5">
          <view-image
              :image="image_path"
              :defaultImage="'promotion'"
              :height="160"
              style="border-radius: 8px"
          ></view-image>
        </v-col>
        <v-col cols="7">
          <p class="text-lg black-text font-bold text-elepsis ml-2 membership_heading">
            {{ name }}
          </p>
          <v-divider/>
          <v-card
              class="overflow-y-hidden  mt-1 shadow-0  "
              style="min-height: 110px; max-height: 110px"
          >
            <div v-if="description" v-html="description" class="p-1"></div>
            <div v-else  class="p-1">
              <p>No Description found</p>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense class="mt-4">
        <v-col cols="6" class="text-center">
          <div class="promos_stats">
            <p class="text-base font-normal text-dark-gray ">Valid Date:</p>
            <p class="text-base blue-text font-medium ">
              {{ start_date | dateformatDDMMYY }}  -  {{ end_date | dateformatDDMMYY }}</p>
          </div>

        </v-col>
        <v-col cols="6" class="text-center">
          <div class="promos_stats ">
            <p class="text-base font-normal text-dark-gray">For:</p>
            <p class="text-base black-text font-medium">
              {{ applied_for == "M" ? "Members only" : "All Customers" }}
            </p>
          </div>
        </v-col>


        <v-col cols="6" class="text-center">
          <div class="promos_stats " v-if="usage_type === 'G'">
            <p class="text-base font-normal text-dark-gray">Promo Code:</p>
            <p class="text-base black-text font-medium text-elepsiss"> {{
                promotion_code
              }}</p>
          </div>
          <div class="promos_stats" v-else-if="promo_codes">
            <p class="text-base font-normal text-dark-gray">Total Codes:</p>
            <p class="text-base black-text font-medium">  {{ promo_codes }} (used {{ used_promo_codes }}) </p>
          </div>
          <div class="promos_stats" v-else>
            <p class="text-base font-normal text-dark-gray">Total Codes:</p>
            <p class="text-base black-text font-medium">  -- </p>
          </div>
        </v-col>
        <v-col cols="6" class="text-center">
          <div class="promos_stats ">
            <p class="text-base font-normal text-dark-gray">Discount YTD:</p>
            <p class="text-base black-text font-medium"> {{Number(discount_ytd) | toCurrency}} </p>
          </div>
        </v-col>
      </v-row>

    </v-card-text>


  </v-card>
</template>
<script>

export default {
  props: {
    id: { type: Number, default: 1 },
    name: { type: String, default: "" },
    start_date: { type: String, default: "0" },
    end_date: { type: String, default: "0" },
    promotion_code: { type: String, default: "0" },
    description: { type: String, default: null },
    image_path: {
      type: String,
      default: null,
    },
    applied_for: { type: String, default: "C" },
    usage_type: { type: String, default: "G" },
    promo_codes: { type: Number, default: 0 },
    used_promo_codes: { type: Number, default: 0 },
    discount_ytd: { type: Number, default: 0 },
    benefits: { type: Array, default: () => [] },
    status_id: { type: Number, default: 1 },
  },

  methods: {
    managePromotion() {
      this.$router.push({
        name: "ManagePromotion",
        params: { data: btoa(this.id) },
      });
    },
    editPromotion() {
      this.$router.push({
        name: "EditPromotion",
        params: { data: btoa(this.id) },
      });
    },
    deletePromotion() {
      this.$emit("delete", {
        id: this.id,
        type: this.status_id == 1 ? "delete" : "reactivate",
      });
    },
    viewPromotion() {
      this.$router.push({
        name: "ViewPromotion",
        params: { data: btoa(this.id) },
      });
    },
  },
};
</script>

<style scoped>
.promos_stats{
  p{
    margin: 0;
    padding: 0;
  }
}

.trainer_card {
  border: 1px solid rgba(17, 42, 70, 0);
  cursor: pointer;
}

.trainer_card:hover{
  border: 1px solid rgba(17, 42, 70, 1);
}

.text-elepsiss {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 95%;
  overflow: hidden;
  text-align: center;
}

</style>
